import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTriggerLogout } from '../../state/profile/profile';
import Modal from '../Modal';
import styles from './LogoutModal.module.scss';
import { ReactComponent as Cart } from '../../images/icons/shop-cart.svg';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';
import { useAuth0 } from '../../utils/auth0';

const LogoutModal = () => {
  const { logout } = useAuth0();
  const config = useSelector((state) => state.config);
  const openLogout = useSelector((state) => state.profile?.openLogout);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setTriggerLogout(false));
  };
  const [checkboxValue, setCheckboxValue] = useState(false);
  const handleChangeCheckbox = () => {
    setCheckboxValue((prev) => !prev);
  };
  const handleLogOut = () => {
    if (checkboxValue) {
      localStorage.setItem('notShowLogoutModal', JSON.stringify(checkboxValue));
    }
    dispatch(setTriggerLogout(false));
    logout({ returnTo: `${process.env.REACT_APP_BASE_URL}/auth/logout` });
  };

  return (
    <Modal
      isOpen={openLogout}
      onOverlayClick={closeModal}
      closeModal={closeModal}
      className={styles.modal}
      data-testid="logout-modal"
      hiddenCloseIcon
    >
      <div className={styles.root}>
        <Cart />
        <h3>
          <FormattedMessage id="logout.title" defaultMessage="There are still so many great deals" />
        </h3>
        <p className={styles.subTitle}>
          <FormattedMessage
            id="logout.subTitle"
            defaultMessage="There are still so many great deals on {webShopName}! Are you sure you want to log out?"
            values={{ webShopName: config.websiteName }}
          />
        </p>
        <Checkbox
          name="logoutCheckbox"
          onClick={handleChangeCheckbox}
          checked={checkboxValue}
          classList={{ root: styles.checkboxLabel }}
        >
          <FormattedMessage id="logout.checkbox" />
        </Checkbox>
        <div className={styles.actionWrapper}>
          <Button variation="secondary" inverted onClick={handleLogOut}>
            <FormattedMessage id="common.logOut" defaultMessage="Log out" />
          </Button>
          <Button variation="secondary" onClick={closeModal}>
            <FormattedMessage id="logout.stay" defaultMessage="Stay on the site" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
